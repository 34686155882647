/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Swiper bundle */
@import 'swiper/css/bundle';

/* Animation */
@import "~animate.css/animate.min.css";

/* Custom font */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/ibm-plex-sans/IBMPlexSans-Regular.ttf');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/ibm-plex-sans/IBMPlexSans-Medium.ttf');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/ibm-plex-sans/IBMPlexSans-SemiBold.ttf');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/ibm-plex-sans/IBMPlexSans-Bold.ttf');
}

/* Additional color variables */
.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

/* Util */
html,
body {
  --ion-font-family: "IBM Plex Sans" !important;
  font-family: "IBM Plex Sans" !important;
}

h1,
h2,
h3,
h4,
h5,
ion-title,
ion-card-title {
  --ion-font-family: "IBM Plex Sans" !important;
  font-family: "IBM Plex Sans" !important;
  outline: none;
}

ion-card {
  outline: none;
}

.disabled {
  opacity: .25;
  pointer-events: none;
}

.opacity-75 {
  opacity: .75;
}

.opacity-100 {
  opacity: 1;
}

.ion-margin-start-small {
  margin-left: 4px;
}

.ion-margin-start-medium {
  margin-left: 8px;
}

.ion-margin-start-large {
  margin-left: 20px;
}

.ion-margin-end-small {
  margin-right: 4px;
}

.ion-margin-end-medium {
  margin-right: 8px;
}

.ion-margin-end-large {
  margin-right: 20px;
}

.ion-margin-top-small {
  margin-top: 4px;
}

.ion-margin-top-medium {
  margin-top: 8px;
}

.ion-margin-top-large {
  margin-top: 20px !important;
}

.ion-margin-bottom-small {
  margin-bottom: 4px;
}

.ion-margin-bottom-medium {
  margin-bottom: 8px;
}

.ion-margin-bottom-large {
  margin-bottom: 20px;
}

.ion-padding-horizontal {
  padding: 0 16px;
}

.ion-padding-vertical {
  padding: 16px 0px;
}

.ion-padding-start-small {
  padding-left: 4px;
}

.ion-padding-start-medium {
  padding-left: 8px;
}

.ion-padding-start-large {
  padding-left: 20px;
}

.ion-padding-end-small {
  padding-right: 4px;
}

.ion-padding-end-medium {
  padding-right: 8px;
}

.ion-padding-end-large {
  padding-right: 20px;
}

.ion-padding-top-small {
  padding-top: 4px;
}

.ion-padding-top-medium {
  padding-top: 8px;
}

.ion-padding-top-large {
  padding-top: 20px;
}

.ion-padding-bottom-small {
  padding-bottom: 4px;
}

.ion-padding-bottom-medium {
  padding-bottom: 8px;
}

.ion-padding-bottom-large {
  padding-bottom: 20px;
}

.ion-no-padding-start {
  --padding-start: 0;
}

.ion-no-padding-end {
  --padding-end: 0;
}

.ion-no-padding-top {
  --padding-top: 0;
}

.ion-no-padding-bottom {
  --padding-bottom: 0;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

.font-size-smallest {
  font-size: 12px !important;
  line-height: 16px !important;
}

.font-size-small {
  font-size: 14px !important;
  line-height: 16px !important;
}

.font-size-medium {
  font-size: 16px !important;
  line-height: 18px !important;
}

.font-size-large {
  font-size: 24px !important;
  line-height: 26px !important;
}

.ion-block {
  display: block;
}

.ion-flex {
  display: flex;
}

.ion-full-height {
  height: 100%;
}

.ion-full-width {
  width: 100%;
}

.ion-flex-center {
  flex-direction: column;
  justify-content: center;
}

.ion-full-height {
  height: 100%;
}

hr {
  background: var(--ion-color-light-shade);
  margin: 32px 0;
}

hr.hr-medium {
  margin: 12px 0;
}

hr.hr-large {
  margin: 22px 0;
}

hr.hr-transparent {
  background: transparent;
}

p {
  margin: 0;
  padding: 0;
}

.ion-no-border {
  border: none;
  --border-width: 0 !important;
}

ion-back-button {
  --icon-font-size: 24px;
  --icon-margin-start: 3px;
  --icon-margin-end: 6px;
}

.item-detail-icon {
  color: var(--ion-color-medium);
  opacity: 1;
}

ion-backdrop {
  background: var(--ion-color-primary-shade);
}

.text-divider {
  position: relative;
  text-align: center;
  margin: 12px 0 24px 0;
}

.text-divider ion-text {
  background: var(--ion-color-white);
  padding: 0 12px;
}

.text-divider span {
  display: block;
  border-top: 1px solid var(--ion-color-light-shade);
  width: 100%;
  margin-top: -9px;
}

/* Toolbar */
ion-toolbar {
  --background: var(--ion-color-white);
}

.header-translucent-ios ion-toolbar {
  --opacity: 1;
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0;
}

/* Animation */
:root {
  --animate-duration: .25s;
}

/* Loading controller */
.default-loading {
  --background: var(--ion-color-white);
  --spinner-color: var(--ion-color-primary);
  --width: 100% !important;
}

.default-loading .loading-wrapper p {
  margin: 0;
  font-size: 16px;
  color: var(--ion-color-primary);
}

.default-loading .loading-wrapper span {
  font-weight: 400;
  margin-top: 6px;
  display: block;
  color: var(--ion-color-medium);
}

/* Buttons */
ion-button {
  --border-radius: 5px;
  font-weight: 500;
  letter-spacing: .005em;
  margin: 0;
}

ion-button.ion-button-small {
  font-size: 12px !important;
}

/* Lists */
.list-custom {
  border-radius: 8px;
}

.list-custom.list-custom-compact ion-item {
  --min-height: 16px;
  --height: 16px;
}

.list-custom.list-custom-compact ion-item ion-label {
  margin: 8px 0;
}

.list-custom-borders {
  border: 1px solid var(--ion-color-light-shade);
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 7%), 0 2px 10px 0 rgb(0 0 0 / 2%);
}

.list-custom-flex {
  display: flex;
}

.list-custom ion-item {
  --border-color: var(--ion-color-light-shade);
  --background: transparent;
  --highlight-color-focused: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
  --background-activated: transparent;
  --background-activated-opacity: 0;
  --background-focused-opacity: 0;
  --background-hover-opacity: 0;
  --ripple-color: transparent;
}

.list-custom.items-no-horizontal-padding ion-item {
  --padding-inline-end: 0;
  --inner-padding-end: 0;
  --padding-start: 0;
  padding-inline-end: 0;
}

.list-custom ion-item:first-child {
  border-radius: 8px 0 0 0;
  --border-radius: 8px 0 0 0;
}

.list-custom ion-item:last-child {
  --border-width: 0;
  border-radius: 0 0 8px 0;
  --border-radius: 0 0 8px 0;
}

.list-custom ion-item:only-child {
  border-radius: 8px;
  --border-radius: 8px;
}

.list-custom ion-item ion-label {
  margin: 16px 0;
}

.list-custom ion-item.item-light {
  --background: var(--ion-color-light);
}

.list-custom ion-list-header {
  --padding-start: 0;
  padding-left: 0;
  color: var(--ion-color-medium);
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 6px;
  text-transform: uppercase;
}

ion-list-header {
  margin: 0 0 8px 0;
  padding: 0;
  display: flex;
  align-items: center;
}

ion-list-header ion-label {
  margin: 0;
  padding: 0;
  font-size: 20px;
}

ion-list-header ion-button {
  font-size: 14px;
  height: 28px;
  --padding-start: 18px;
  --padding-end: 16px;
}

ion-list.item-card-list {
  background: transparent;
  margin: 0 -16px;
}

ion-list.item-card-list.form-list-borders {
  border: 1px solid var(--ion-color-light-shade);
  margin: 0;
  border-radius: 8px;
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 7%), 0 2px 10px 0 rgb(0 0 0 / 2%);
}

ion-list.item-card-list.form-list-borders ion-item {
  margin: 3px 0;
  border-bottom: 1px solid var(--ion-color-light-shade);
  border-radius: 0 !important;
}

ion-list.item-card-list.form-list-borders ion-item:last-child {
  border: none;
}

ion-list.item-card-list ion-item {
  margin: 0 16px 12px 16px;
  --border-radius: 8px;
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 7%), 0 2px 10px 0 rgb(0 0 0 / 2%);
  --padding-start: 16px;
}

ion-list.item-card-list ion-item ion-label {
  margin: 16px 0;
}

ion-list ion-item ion-label.sc-ion-label-ios-s h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
}

ion-list ion-item ion-label.sc-ion-label-ios-s p {
  font-size: 14px;
  color: var(--ion-color-medium);
}

ion-list-header.ion-list-header-small {
  margin: 0;
}

ion-list-header.ion-list-header-small ion-label {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--ion-color-medium);
}

p.list-description {
  font-size: 12px;
  color: var(--ion-color-medium);
  margin: 6px 0 0 0;
}

ion-list ion-item ion-label.sc-ion-label-ios-s p.list-description {
  font-size: 12px;
  margin-top: 3px;
}

/* Loading spinner */
.spinner-container {
  width: 100%;
  height: 100%;
  text-align: center;
  position: absolute;
  z-index: 200;
  background-color: var(--ion-color-white);
}

/* Tab bar */
ion-tabs {
  position: relative;
}

ion-tab-bar {
  --background: var(--ion-color-white);
  --border: 1px solid var(--ion-color-light-shade);
}

ion-tab-button {
  --color: var(--ion-color-medium);
  --color-focused: var(--ion-color-primary);
  --color-selected: var(--ion-color-primary);
}

ion-tab-button ion-icon {
  font-size: 20px;
  margin: 3px 0 1px 0;
}

ion-tab-button ion-label {
  font-size: 10px;
}

/* Action Sheet */
ion-action-sheet.custom-action-sheet {
  --backdrop-opacity: .8;
}

.custom-action-sheet .action-sheet-group {
  border-radius: 5px;
  background-color: var(--ion-color-white);
}

.custom-action-sheet .action-sheet-title {
  text-align: left;
  color: var(--ion-color-medium);
  padding: 12px 18px;
  font-weight: 500;
  font-size: 14px;
}

.custom-action-sheet .action-sheet-sub-title.sc-ion-action-sheet-ios {
  padding: 0;
  font-weight: 400;
  font-size: 12px;
}

.custom-action-sheet button.action-sheet-button {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  color: var(--ion-color-primary);
}

.custom-action-sheet button.action-sheet-button.action-sheet-cancel {
  color: var(--ion-color-medium);
  background: var(--ion-color-light);
}

.custom-action-sheet button.action-sheet-button.action-sheet-destructive {
  color: var(--ion-color-danger);
  background: rgba(var(--ion-color-danger-rgb), .1);
}

.custom-action-sheet button.action-sheet-button.action-sheet-destructive ion-icon {
  color: var(--ion-color-danger);
}

.custom-action-sheet .action-sheet-cancel.sc-ion-action-sheet-ios::after {
  background: transparent;
}

.custom-action-sheet button.action-sheet-button.action-sheet-cancel ion-icon {
  color: var(--ion-color-medium);
}

.custom-action-sheet .action-sheet-button-inner {
  text-align: left;
  justify-content: left;
}

.custom-action-sheet .action-sheet-button-inner ion-icon {
  color: var(--ion-color-medium);
  width: 28px;
  margin-right: 16px !important;
}

/* Alert */
ion-alert.sc-ion-alert-ios-h {
  --backdrop-opacity: .8;
}

.alert-wrapper.sc-ion-alert-ios {
  background: var(--ion-color-secondary);
  border-radius: 8px;
  width: 100%;
  max-width: calc(100% - 16px);
  padding: 24px 0px 8px 0;
}

.alert-head.sc-ion-alert-ios {
  padding-top: 0;
  padding-bottom: 6px;
}

.alert-title.sc-ion-alert-ios {
  margin-top: 0px;
  font-size: 18px;
  font-weight: 600;
}

.alert-message.sc-ion-alert-ios {
  font-size: 14px;
  color: var(--ion-color-primary);
}

.alert-button.sc-ion-alert-ios.danger {
  color: var(--ion-color-white);
  font-weight: 500;
  border: none;
  background: var(--ion-color-danger);
  margin: 0px 16px;
  border-radius: 3px;
}

.alert-button.sc-ion-alert-ios.alert-button-role-cancel {
  color: var(--ion-color-medium);
  font-weight: 500;
  border: none;
  background: transparent;
  margin: 0px 16px;
  border-radius: 3px;
}

.alert-button.sc-ion-alert-ios:last-child {
  margin-top: 6px !important;
}

.alert-button-group.sc-ion-alert-ios {
  max-width: 75%;
  margin: 0 auto;
}

/* Popover */
ion-popover.popover-in-modal {
  --offset-x: -10px;
  --backdrop-opacity: .25;
}

ion-popover.popover-in-modal ion-radio-group ion-item {
  --inner-border-width: 0;
  border-bottom: 1px solid var(--ion-color-primary);
  font-size: 14px;
}

ion-popover.popover-in-modal ion-radio-group ion-item:last-child {
  border: none;
}

ion-popover.popover-in-modal ion-list ion-item {
  --inner-border-width: 0;
  --background: var(--ion-color-primary);
}

/* Range */
ion-item.item-range {
  --padding-start: 0;
  --inner-padding-end: 0;
  padding-top: 0;
}

ion-item.item-range ion-range {
  padding: 0;
  --bar-height: 6px;
  --bar-background: var(--ion-color-light-shade);
  --knob-background: var(--ion-color-white);
}

/* Note */
ion-note.ion-note-small {
  font-size: 14px;
  font-weight: 400;
}

/* Searchbar */
ion-searchbar.sc-ion-searchbar-ios-h {
  --cancel-button-color: var(--ion-color-medium);
  --background: var(--ion-color-primary-tint);
  --border-radius: 8px;
}

/* Skeleton */
ion-skeleton-text {
  --border-radius: 3px;
}

ion-avatar ion-skeleton-text {
  --border-radius: 50%;
}

/* Forms */
ion-input {
  --placeholder-color: var(--ion-color-medium);
}

form.form-default ion-list.item-card-list ion-item {
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgb(126 144 169 / 7%), 0 2px 5px 0 rgb(126 144 169 / 2%);
}

form.form-default ion-list.item-card-list ion-item.item-has-focus {
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgb(126 144 169 / 7%), 0 2px 5px 0 rgb(126 144 169 / 2%);
}

form.form-default ion-list.item-card-list ion-item ion-label {
  margin: 12px 0 -6px 0;
  font-weight: 500;
}

form.form-default ion-list.item-card-list ion-item.item-label-floating ion-input {
  font-size: 16px;
  --padding-top: 0;
  --padding-bottom: 0;
  margin-bottom: 8px;
}

small.error-message-form {
  display: block;
  margin: -6px 16px 12px 16px;
  font-weight: 500;
}

/* Logo */
.logo-container {
  text-align: center;
  margin-bottom: 40px;
}

h1.logo-placeholder {
  font-weight: 600;
  background: -webkit-linear-gradient(var(--ion-color-medium), var(--ion-color-primary));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-size: 36px;
  margin: 0 0 6px 0;
}

.logo-container p {
  color: var(--ion-color-medium);
}

/* Borders */
.border-default {
  border: 1px solid var(--ion-color-light);
}

/* Box Shadow */
.box-shadow-default {
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 7%), 0 2px 10px 0 rgb(0 0 0 / 2%);
}

/* Hero */
.hero {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 30px 0 0 0;
}

.hero ion-avatar {
  text-align: center;
  margin-bottom: 3px;
}

.hero ion-avatar .avatar-icon-placeholder {
  font-size: 42px;
}

/* Swiper rooms */
swiper.swiper-rooms {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 32px;
  margin-bottom: 16px;
}

swiper.swiper-rooms ion-card {
  border: 1px solid var(--ion-color-light-shade);
  outline: none;
}

swiper.swiper-rooms ion-card ion-card-content h2 {
  font-size: 16px;
  font-weight: 600;
  color: var(--ion-color-primary);
  margin-bottom: 10px;
}

swiper.swiper-rooms ion-card ion-card-content div {
  color: var(--ion-color-medium);
}

swiper.swiper-rooms ion-card ion-card-content div p {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 6px;
}

swiper.swiper-rooms ion-card ion-card-content div p:last-child {
  margin-bottom: 0;
}

swiper.swiper-rooms ion-card ion-card-content div p ion-icon {
  margin-right: 6px;
}

/* Grid custom */
ion-grid.grid-custom {
  padding: 0 10px;
}

/* Card device */
ion-card.card-device {
  border: 1px solid var(--ion-color-light-shade);
}

ion-card.card-device.active {
  --background: rgba(var(--ion-color-secondary-rgb), .02);
  border: 1px solid var(--ion-color-secondary);
  box-shadow: 0 4px 10px 0 rgba(var(--ion-color-secondary-rgb), .15), 0 2px 10px 0 rgba(var(--ion-color-secondary-rgb), .15);
}

ion-card.card-device ion-card-content h2 {
  font-size: 16px;
  font-weight: 600;
  color: var(--ion-color-medium);
  margin-bottom: 6px;
}

ion-card.card-device.active ion-card-content h2 {
  color: var(--ion-color-primary);
}

ion-card.card-device ion-card-content div p {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 6px;
  color: var(--ion-color-medium);
}

ion-card.card-device ion-card-content div p.disabled {
  color: var(--ion-color-light-shade);
}

ion-card.card-device ion-card-content div p:last-child {
  margin-bottom: 0;
}

ion-card.card-device ion-card-content div p ion-icon {
  margin-right: 6px;
}

ion-card .toggle-container {
  justify-content: space-between;
  margin-top: 22px;
  font-weight: 500;
  font-size: 14px !important;
}

ion-card.active .toggle-container {
  color: var(--ion-color-secondary);
}

ion-card .toggle-container ion-toggle {
  --handle-width: 21px;
  height: 22px;
  width: 42px;
}

ion-card .toggle-container ion-toggle::before {
  position: absolute;
  top: 50%;
  transform: translatey(-50%);
  left: 8px;
  content: "|";
  color: white;
  font-size: 10px;
  z-index: 1;
}

ion-card.card-device.disabled {
  pointer-events: none;
  opacity: .5 !important;
}

ion-card.card-device .card-main-icon {
  color: var(--ion-color-light-shade);
  font-size: 24px;
}

ion-card.card-device.active .card-main-icon {
  color: var(--ion-color-light-shade);
}

/* Avatar */
ion-avatar.ion-avatar-default-icon {
  background: var(--ion-color-white);
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 1%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
}

ion-avatar.ion-avatar-default-icon.ion-avatar-default-icon-light {
  background: var(--ion-color-light);
  border: 1px solid var(--ion-color-light-shade);
}

ion-avatar.ion-avatar-default-icon ion-icon {
  margin-top: -1px;
  font-size: 20px;
}

/* Chart Card */
ion-card.ion-card-chart-basic {
  box-shadow: none;
}

ion-card .ion-chart-card-title {
  font-size: 18px;
  color: var(--ion-color-primary);
  font-weight: 600;
}

ion-card.ion-card-chart-default {
  border: 1px solid var(--ion-color-light-shade);
  padding: 16px;
  // box-shadow: none;
}

ion-card.ion-card-chart ion-card-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 0 16px 0;
}

ion-card.ion-card-chart ion-card-header > div {
  text-align: right;
  display: flex;
}

ion-card.ion-card-chart ion-card-header > div > div:last-child {
  margin-left: 22px;
}

ion-card.ion-card-chart ion-card-title {
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end
}

ion-card.ion-card-chart ion-card-title ion-icon {
  margin-left: 2px;
}

ion-card.ion-card-chart ion-card-subtitle {
  font-size: 11px;
  font-weight: 400;
  color: var(--ion-color-medium);
  text-transform: none;
  margin: 0;
}

/* Avatars stacked */
.avatars-stacked {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: 12px;
  color: var(--ion-color-medium);
}

.avatars-stacked div {
  max-width: 62px;
  margin-right: -10px;
}

.avatars-stacked div img {
  border-radius: 50%;
  border: 3px solid var(--ion-color-white);
}

/* Badge */
ion-badge {
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  padding: 6px 6px;
  margin: 0 0;
  display: flex;
  align-items: center;
}

ion-badge ion-icon {
  margin-right: 3px;
}

ion-badge.ion-color-primary {
  color: var(--ion-color-primary);
  background: rgba(var(--ion-color-primary-rgb), .1);
}

ion-badge.ion-color-success {
  color: var(--ion-color-success);
  background: rgba(var(--ion-color-success-rgb), .1);
}

ion-badge.ion-color-danger {
  color: var(--ion-color-danger);
  background: rgba(var(--ion-color-danger-rgb), .1);
}

ion-badge.ion-color-medium {
  color: var(--ion-color-white);
  background: rgba(var(--ion-color-medium-rgb), .25);
}

/* Item rating slot */
ion-item .rating-slot {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 10%;
}

ion-item .rating-slot p {
  font-size: 16px;
  font-weight: 600;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

ion-item .rating-slot span {
  font-size: 12px;
  font-weight: 400;
}

ion-item .rating-slot.rating-slot-success p {
  color: var(--ion-color-success);
  background: rgba(var(--ion-color-success-rgb), 0.15);
}

ion-item .rating-slot.rating-slot-success span {
  color: var(--ion-color-success);
}

ion-item .rating-slot.rating-slot-warning p {
  color: var(--ion-color-warning);
  background: rgba(var(--ion-color-warning-rgb), 0.15);
}

ion-item .rating-slot.rating-slot-warning span {
  color: var(--ion-color-warning);
}

/* Chip */
ion-chip.ion-chip-small {
  font-size: 12px;
  padding: 0 12px;
  height: 20px;
  font-weight: 500;
}

ion-chip.ion-chip-small.icon-only {
  padding: 0 6px;
    margin: 0 6px;
}

ion-chip.ion-chip-small.icon-only ion-icon {
  margin: 0;
}

ion-chip.ion-chip-small ion-icon {
  font-size: 12px;
}

/* Modal */
ion-footer.modal-footer {
  padding: 8px 0;
}

/* Accordion */
ion-accordion-group.custom-accordion-group ion-item {
  --border-color: var(--ion-color-light-shade);
  --padding-start: 0;
  --padding-inline-end: 0;
  --inner-padding-end: 0;
  padding-inline-end: 0;
  --background: transparent;
  --highlight-color-focused: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
  --background-activated: transparent;
  --background-activated-opacity: 0;
  --background-focused-opacity: 0;
  --background-hover-opacity: 0;
  --ripple-color: transparent;
  --border-color: var(--ion-color-light-shade);
}

ion-accordion-group.custom-accordion-group ion-item ion-icon {
  font-size: 18px;
}

ion-accordion-group.custom-accordion-group ion-item ion-label.sc-ion-label-ios-h {
  font-weight: 500;
}

ion-accordion-group.custom-accordion-group ion-list {
  padding-bottom: 12px;
}

ion-accordion-group.custom-accordion-group ion-list ion-item ion-label.sc-ion-label-ios-h {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

ion-accordion-group.custom-accordion-group ion-list ion-item {
  --padding-start: 12px;
  --border-radius: 3px;
  margin-left: 26px;
  --inner-padding-end: 0px;
  --min-height: 36px;
  --border-color: var(--ion-color-light-shade);
}

ion-accordion-group.custom-accordion-group ion-list ion-item:last-child {
  --inner-border-width: 0;
}

ion-accordion-group.custom-accordion-group ion-list ion-item.item-radio-checked {
  --background: rgba(var(--ion-color-secondary-rgb), 0.1);
  --border-color: var(--ion-color-secondary);
}

ion-accordion-group.custom-accordion-group ion-list ion-item.item-radio-checked ion-label.sc-ion-label-ios-h {
  --color: var(--ion-color-secondary-shade);
}

.card-grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 20px;
}

.card-grid > ion-card {
  width: 100%;
}

.half_doughnut_custom_title {
  font-size: 18px;
  color: var(--ion-color-primary);
  font-weight: 600;
  text-align: center;
  margin: -82px 0 0 0;
}

.half_doughnut_custom_title span {
  display: block;
  font-size: 12px;
  color: var(--ion-color-medium);
  font-weight: 400;
}

.item-chart-container {
  border-radius: 10px;
  height: calc(100% - 32px);
  width: 80px;
  padding: 0 4px;
  background-color: rgba(var(--ion-color-tertiary-rgb), 0.05);
  // background: radial-gradient(circle, rgba(var(--ion-color-tertiary-rgb), 0.25) 0%, transparent 80%);
}

.item-chart-container canvas {
  border-radius: 10px;
  height: 100% !important;
  width: 100% !important;
}